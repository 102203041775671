<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> Voltage Optimisation Survey Forms
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="vosurveys"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >

          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-card-title>
                <v-btn
                    color="green"
                    dark
                    outlined
                    @click.stop="viewVosurvey(item.id)">
                  View Details
                </v-btn>
              </v-card-title>
              <v-card-title>
                <v-btn
                    color="red"
                    dark
                    outlined
                    @click="delRecord(item.id)">
                  Delete
                </v-btn>
              </v-card-title>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>

    <ConfirmDialog ref="confirmDelete" />
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";


export default {
  name: "Vosurveys",
  components: {
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      items: [],
      selectedRows: [],
      headers: [
        {
          text: 'Enquiry Reference',
          sortable: true,
          value: 'enquiryReference',
          align: 'left',
        },
        {
          text: 'Site Name',
          sortable: true,
          value: 'siteName',
          align: 'left',
        },
        {
          text: 'Site Postcode',
          sortable: true,
          value: 'sitePostcode',
          align: 'left',
        },
        {
          text: 'Survey Date',
          sortable: true,
          value: 'surveyDate',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('vosurveys', {loadingVosurveys : 'isFindPending'}),
    ...mapGetters('vosurveys', {findVosurveysInStore : 'find'}),

    vosurveys() {
      return this.findVosurveysInStore().data || [];
    },
    selectedVosurveyIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  methods: {
    ...mapActions('vosurveys', { findVosurveys: 'find' }),
    deleteItem(id) {
      this.$store.dispatch('vosurveys/remove', id)
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    viewVosurvey(id) {
      this.$router.push('/app/vosurvey/'+id)
    },
    getVosurveys(){
      fetch('https://emissis-app.com/api/reports/vosurvey?vosurveyIds='+this.selectedVosurveyIds, {
        method: 'GET',
        headers: {"Authorization": "Bearer " + this.$store.state.auth.accessToken}
      }).then((res) => {
        console.log(res);
        window.location = 'https://emissis-app.com/api/assetList.pdf'
      });
      console.log(this.selectedVosurveyIds);
    },
  },
  mounted() {
    this.findVosurveys();
  }
}
</script>

<style scoped>

</style>
